"use strict";
/**
 * Created by hustcc on 18/5/20.
 * Contract: i@hust.cc
 */
Object.defineProperty(exports, "__esModule", { value: true });
var format_1 = require("./format");
exports.format = format_1.format;
var realtime_1 = require("./realtime");
exports.render = realtime_1.render;
exports.cancel = realtime_1.cancel;
var locales_1 = require("./locales");
exports.register = locales_1.register;
