import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CssBaseline } from '@mui/material';
import {
    ThemeProvider,
    createTheme,
    StyledEngineProvider
} from '@mui/material/styles';
//
import shape from './shape';
import lightPalette from './Palette/lightPalette';
import darkPalette from './Palette/darkPalette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

const ThemeConfig = ({ children }) => {
    const themeState = (state) => state.theme.mode;
    const storeState = useSelector(themeState);

    const [themeMode, setTheme] = useState(storeState);
    useEffect(() => {
        setTheme(storeState);
    }, [themeMode, storeState]);

    const palette =
        themeMode === true || themeMode === 'true' ? darkPalette : lightPalette;

    const themeOptions = useMemo(
        () => ({
            breakpoints,
            palette,
            shape,
            typography,
            shadows,
            customShadows
        }),
        [palette]
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

ThemeConfig.propTypes = {
    children: PropTypes.node
};

ThemeConfig.defaultProps = {
    children: {}
};

export default ThemeConfig;
