import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiConfig from '../config/ApiConfig';

let token = localStorage.getItem('token');

export const createSubCategory = async (formData, majorId, setOpen) => {
    token = localStorage.getItem('token');
    console.log('Token', token);
    const response = await Axios.post(
        `${ApiConfig.subCategory.createCategory}/${majorId}`,
        formData,
        {
            headers: {
                Accept: '*',
                Authorization: `Bearer ${token}`
            }
        }
    );

    console.log('Sub category Created', response.data);
    if (response.data.success) {
        toast.success('Sub category Created successfully');
    }
    setOpen(false);
    return response.data.data;
};

export const getSubCategoriesById = async (majorId) => {
    const response = await Axios.get(
        `${ApiConfig.subCategory.getSubCategoriesById}/${majorId}`
    );
    console.log('Subcats Fetched', response.data);
    return response.data.data;
};
export const getAllCategories = async () => {
    const response = await Axios.get(ApiConfig.category.getAllCategories);
    console.log('response', response.data.data);
    return response.data.data;
};
