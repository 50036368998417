import { Card, CardContent, Typography, Stack, Box } from '@mui/material';
import PropTypes from 'prop-types';

const StatusInner = ({ courses, text }) => (
    <Stack direction="row" mt={1} alignItems="center">
        <Box>
            <Typography variant="h2">{courses}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" ml={2}>
            <Typography variant="h4">{text}</Typography>
        </Box>
    </Stack>
);

const CourseStatus = () => (
    <Card>
        <CardContent>
            <Typography variant="subtitle1">Courses Status</Typography>
            <StatusInner courses={0} text="Instructors" />
            <StatusInner courses={0} text="Students" />
            <StatusInner courses={0} text="Courses" />
        </CardContent>
    </Card>
);

StatusInner.propTypes = {
    courses: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired
};

export default CourseStatus;
