import { Box, Grid, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import SecondaryHeader from '../../../common/components/SecondaryHeader';

const ListAnalysis = ({ data, users }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                [theme.breakpoints.up('sm')]: {
                    display: 'none'
                }
            }}
        >
            <SecondaryHeader title={users ? 'Users' : 'Courses'} />
            <Grid container spacing={1}>
                {data?.map((obj, index) => (
                    <Grid item key={obj.index} xs={12}>
                        <Box display="flex" alignItems="center" mt={1}>
                            <Typography variant="body1" sx={{ flex: 0.1 }}>
                                {index + 1}.
                            </Typography>

                            <Typography sx={{ flex: 0.3 }}>avatar</Typography>
                            <Typography variant="h3" sx={{ flex: 1 }}>
                                {obj.name}
                            </Typography>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="caption">
                                    Created On
                                </Typography>
                                <Typography variant="capton">
                                    {obj.createdOn}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Typography variant="caption">
                                <Typography variant="caption" color="secondary">
                                    {/* {obj.enrolledCourses} */}
                                </Typography>{' '}
                                Enrolled Courses
                            </Typography>
                            <Typography variant="caption">
                                <Typography variant="caption" color="secondary">
                                    {obj.completedCourses}{' '}
                                </Typography>
                                Completed Courses
                            </Typography>
                            <Typography variant="caption">
                                <Typography variant="caption" color="secondary">
                                    {obj.enrolledCategories}{' '}
                                </Typography>
                                Enrolled Sub Categories
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

ListAnalysis.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    users: PropTypes.bool.isRequired
};

export default ListAnalysis;
