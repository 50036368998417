import { useState, useEffect } from 'react';
import {
    Stack,
    Box,
    Button,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { createSubCategory, getSubCategoriesById } from '../../../API/Category';
import SubCatImg from '../../../assets/images/catBack.png';
import BreadCrumb from '../../../common/components/Breadcrumb/index';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const breadcrumb = [
    {
        id: '01',
        breadItem: 'categories',
        link: '/dashboard/category'
    },
    {
        id: '02',
        breadItem: 'subcategory'
    }
];

const validationSchema = Yup.object({
    categoryName: Yup.string().required('Category Name is required'),
    categoryCode: Yup.string().max(8, 'Category Code should be at most 8 characters').required('Category Code is required'),
  });

const SubCategory = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [subCategories, setSubCategories] = useState();
    const [formData, setData] = useState({
        categoryName: '',
        categoryCode: ''
    });
    console.log('Sub cat location subCats', location.state);
    const majorId = location.state.majorId;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        formik.setFieldValue('categoryName', '');
        formik.setFieldValue('categoryCode', '');
    };
    const handleChange = (event) =>
        setData({ ...formData, [event.target.name]: event.target.value });

      
    useEffect(
        () =>
            getSubCategoriesById(majorId).then((response) => {
                console.log('got sub categories', response);
                setSubCategories(response);
            }),
        [majorId, open]
    );

    const formik = useFormik({
        initialValues: {
          categoryName: '',
          categoryCode: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
          createSubCategory(values, majorId, setOpen);
          formik.setFieldValue('categoryName', '');
            formik.setFieldValue('categoryCode', '');
        },
      });

    return (
        <Stack>
            <Box mb={1.5} mx={{ xs: 4, md: 9 }}>
                {' '}
                <BreadCrumb breadItems={breadcrumb} />
            </Box>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-end"
                marginLeft={-500}
                marginRight={10}
                mb={4}
            >
                <Button
                    variant="contained"
                    onClick={handleClickOpen}
                    color="secondary"
                >
                    Add Sub Category
                </Button>
            </Stack>

            <Grid
                container
                spacing={{ xs: 5, md: 6, xl: 12 }}
                sx={{ mt: 0 }}
                p={4}
            >
                {subCategories?.length > 0 ? (
                    subCategories?.map((item) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            width="288px"
                            height="306px"
                            mb={4}
                            columnSpacing={2}
                        >
                            {/* <Link href="/dashboard/category/subcategory/subcatdetails"> */}
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={SubCatImg}
                                    alt="thumbnail"
                                />
                                <CardContent>
                                    <Chip label={item?.categoryCode} />
                                    <Typography variant="h6" color="#09111C">
                                        {item?.categoryName}
                                    </Typography>
                                </CardContent>
                            </Card>
                            {/* </Link> */}
                        </Grid>
                    ))
                ) : (
                    <Grid item>
                        <Typography variant="h5">
                            No Subcategories found
                        </Typography>
                    </Grid>
                )}
            </Grid>
            {/* <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add a Sub Category</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter category name and category code here. Category
                        code can be 8 characters long only.
                    </DialogContentText>
                    <Box display="flex" flexDirection="column">
                        <TextField
                            variant="standard"
                            name="categoryName"
                            onChange={handleChange}
                            label="Enter category name here"
                        />
                        <TextField
                            variant="standard"
                            label="Enter category code"
                            name="categoryCode"
                            onChange={handleChange}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={() =>
                            createSubCategory(formData, majorId, setOpen)
                        }
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog> */}
                  <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a Sub Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter category name and category code here. Category code can be 8 characters long only.
          </DialogContentText>
          <Box display="flex" flexDirection="column">
            <TextField
              variant="standard"
              name="categoryName"
            //   onChange={formik.handleChange}
            onChange={(e) => {
                const trimmedValue = e.target.value.replace(/^\s+/, ''); // Trim spaces before any character
                formik.handleChange(e);
                formik.setFieldValue('categoryName', trimmedValue);
            }}
              onBlur={formik.handleBlur}
              value={formik.values.categoryName}
              error={formik.touched.categoryName && Boolean(formik.errors.categoryName)}
              helperText={formik.touched.categoryName && formik.errors.categoryName}
              label="Enter category name here"
            />
            <TextField
              variant="standard"
              label="Enter category code"
              name="categoryCode"
            //   onChange={formik.handleChange}
            onChange={(e) => {
                const trimmedValue = e.target.value.replace(/^\s+/, ''); // Trim spaces before any character
                formik.handleChange(e);
                formik.setFieldValue('categoryCode', trimmedValue);
            }}
              onBlur={formik.handleBlur}
              value={formik.values.categoryCode}
              error={formik.touched.categoryCode && Boolean(formik.errors.categoryCode)}
              helperText={formik.touched.categoryCode && formik.errors.categoryCode}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={formik.handleSubmit} disabled={!formik.isValid || formik.isSubmitting}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
        </Stack>
    );
};

export default SubCategory;
