import { Navigate, useRoutes } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import LoginLayout from '../common/layout/Auth';
import DashboardLayout from '../common/layout/Dashboard';
import Login from '../containers/Auth/Login';
import AllCourses from '../containers/Pages/AllCourses';
// import CreateCourse from '../containers/Pages/CreateCourse/CreateCourse';
import CreateCourse from '../containers/Pages/CreateCourse';
import ViewCourse from '../containers/Pages/ViewCourse';
import ViewModule from '../containers/Pages/ViewModule';
import Analysis from '../containers/Pages/Analysis';
import AddUsers from '../containers/Pages/Users/AddUsers';
import Users from '../containers/Pages/Users';
// import CreateLesson from '../containers/Pages/CreateCourse/CreateLesson';
import NotFound from '../containers/Page404';
import AdminCategory from '../containers/Pages/category/index';
import SubCategory from '../containers/Pages/subCategory/subCategory';
import SubCategoryDetails from '../containers/Pages/subCategoryDetails/subCategoryDetails';
import ChatBox from '../chatBox/chatBox';
import Dashboard from '../containers/Pages/Dashboard';
import Mycourses from '../containers/Pages/MyCourses';
import VirtualLab from '../containers/Pages/VirtualLab';
import Main from '../containers/Pages/AdminDashboard/Main';
import { Circular } from '../containers/Pages/Circular';
import { Polls } from '../containers/Pages/Polls';
import { Survey } from '../containers/Pages/Survey';
import { SurveyDetails } from '../containers/Pages/Survey/SurveyDetails';
import { PollDetails } from '../containers/Pages/Polls/PollDetails';

const Router = () => {
    const isLoggedIn = localStorage.getItem('token');
    const user = isLoggedIn && jwt_decode(isLoggedIn);
    const userRole = user?.role;
    userRole;

    return useRoutes([
        {
            path: '/',

            element: !isLoggedIn ? (
                <LoginLayout />
            ) : (
                <Navigate to="/dashboard" />
            ),
            children: [
                { path: '', element: <Login /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" /> }
            ]
        },
        {
            path: '/dashboard',
            element: !isLoggedIn ? <Navigate to="/" /> : <DashboardLayout />,
            children: [
                { path: '', element: <Dashboard /> },
                { path: 'courses/my-courses', element: <Mycourses /> },
                { path: 'lab', element: <VirtualLab /> },
                {
                    path: 'courses/track-progress',
                    element: <Main />
                },
                {
                    path: 'courses/create-course',
                    element: <CreateCourse />
                },
                // {
                //     path: 'create-lesson',
                //     element: <CreateLesson />
                // },
                { path: 'courses', element: <AllCourses /> },
                { path: 'view-course/:courseId', element: <ViewCourse /> },
                { path: 'courses/view-module', element: <ViewModule /> },
                {
                    path: 'courses/edit-course/:courseId',
                    element: <CreateCourse />
                },
                // { path: 'view-module', element: <ViewModule /> },
                { path: 'analysis', element: <Analysis /> },
                { path: 'polls', element: <Polls /> },
                { path: 'poll-details/:id', element: <PollDetails /> },
                { path: 'survey', element: <Survey /> },
                { path: 'survey-details/:id', element: <SurveyDetails /> },
                { path: 'circular', element: <Circular /> },
                { path: 'users', element: <Users /> },
                { path: 'users/add-users', element: <AddUsers /> },

                { path: 'category', element: <AdminCategory /> },
                { path: 'chatbox', element: <ChatBox /> },

                { path: 'category/subcategory', element: <SubCategory /> },
                {
                    path: 'category/subcategory/subcatdetails',
                    element: <SubCategoryDetails />
                },
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" /> }
            ]
        },

        { path: '*', element: <Navigate to="/404" replace /> }
    ]);
};

export default Router;
