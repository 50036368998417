import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Button, Stack } from '@mui/material';

const rows = [
    {
        name: 'rahul kumar',
        email: 'rahulkumar@gmail.com'
    },
    {
        name: 'karan kumar',
        email: 'karankumar@gmail.com'
    },
    {
        name: 'amit kumar',
        email: 'amitkumar@gmail.com'
    },
    {
        name: 'rahul kumar',
        email: 'rahulkumar@gmail.com'
    }
];

const studentRows = [
    {
        name: 'kajal kumar',
        email: 'rahulkumar@gmail.com'
    },
    {
        name: 'pooja kumar',
        email: 'poojakumar@gmail.com'
    },
    {
        name: 'ravi kumar',
        email: 'ravikumar@gmail.com'
    },
    {
        name: 'rahul kumar',
        email: 'rahulkumar@gmail.com'
    },
    {
        name: 'rahul kumar',
        email: 'rahulkumar@gmail.com'
    },
    {
        name: 'rahul kumar',
        email: 'rahulkumar@gmail.com'
    },
    {
        name: 'rahul kumar',
        email: 'rahulkumar@gmail.com'
    }
];
const SubCatCourse = () => (
    <Stack p={4}>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Instructors <Button>(Add instructor)</Button>
                        </TableCell>
                        <TableCell align="right">
                            {rows.length} instructors
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0
                                }
                            }}
                        >
                            <TableCell component="th" scope="row">
                                <Stack direction="row">
                                    <Avatar
                                        sx={{ width: 54, height: 54 }}
                                        alt="Remy Sharp"
                                        src="https://m.media-amazon.com/images/M/MV5BMmNhZDlkYmQtZDM5Yy00MDE5LTg3MGEtZjEwZjlmNzExZmMwXkEyXkFqcGdeQWRvb2xpbmhk._V1_QL75_UY281_CR0,0,500,281_.jpg"
                                    />
                                    &nbsp;&nbsp;
                                    {row.name}
                                </Stack>
                            </TableCell>
                            <TableCell align="right">{row.email}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        {/* student table */}
        <div
            style={{
                marginTop: '80px',
                marginBottom: '40px',
                borderBottom: '5px solid pink'
            }}
        />

        <TableContainer component={Paper}>
            <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                overflowX="hide"
            >
                <TableHead overflowX="hide">
                    <TableRow>
                        <TableCell>
                            Students <Button>(Add Student)</Button>
                        </TableCell>
                        <TableCell align="right" xs={{ display: 'none' }}>
                            {studentRows.length} Students
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {studentRows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0
                                }
                            }}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                xs={{ display: 'none' }}
                            >
                                <Stack direction="row">
                                    <Avatar
                                        alt="Remy Sharp"
                                        src="https://cdn.pixabay.com/photo/2019/02/20/10/51/spiderman-4008954__340.jpg"
                                        sx={{ width: 54, height: 54 }}
                                    />
                                    &nbsp;&nbsp;
                                    {row.name}
                                </Stack>
                            </TableCell>
                            <TableCell align="right">{row.email}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Stack>
);

export default SubCatCourse;
