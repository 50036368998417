import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Box,
    Grid,
    TextField,
    Card,
    CardContent,
    InputAdornment,
    IconButton
} from '@mui/material';
import { AttachFile, Send } from '@mui/icons-material';
import { io } from 'socket.io-client';
import jwt_decode from 'jwt-decode';
import Message from './Message';

const Messenger = () => {
    const [conversations, setConversations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [media, setMedia] = useState('');
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const socket = useRef();
    const token = localStorage.getItem('token');
    const user = jwt_decode(token);
    console.log('localUser fetched', user);
    console.log('online users', onlineUsers);
    const scrollRef = useRef();

    useEffect(() => {
        socket.current = io('ws://localhost:8900');
        socket.current.on('getMessage', (data) => {
            setArrivalMessage({
                sender: data.senderId,
                text: data.text,
                media: data.media,
                mediaFormat: data.mediaFormat,
                createdAt: Date.now(),
                chatId: data.conversationId
            });
        });
    }, []);

    useEffect(() => {
        console.log(
            'current chat members',
            currentChat?.members.includes(arrivalMessage?.sender),
            currentChat,
            arrivalMessage?.sender
        );
        arrivalMessage &&
            currentChat?.members.includes(arrivalMessage.sender) &&
            arrivalMessage.chatId === currentChat._id &&
            setMessages((prev) => [...prev, arrivalMessage]);
    }, [arrivalMessage, currentChat]);
    useEffect(() => {
        socket.current.emit('addUser', user._id, user.firstName);
        socket.current.on('getUsers', (users) => {
            console.log('Socket users found', users);
            setOnlineUsers(users.filter((f) => f.userId !== user._id));
            console.log('Groups', groups);
        });
    }, [user._id, user.firstName, groups]);

    useEffect(() => {
        const getConversations = async () => {
            try {
                const res = await axios.get(
                    'http://localhost:5000/conversations/' + user._id
                );
                console.log('conversation', res.data);
                setConversations(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        getConversations();
    }, [user._id]);

    useEffect(() => {
        const getGroups = async () => {
            try {
                const res = await axios.get(
                    'http://localhost:5000/groups/' + user._id
                );
                console.log('groups', res);
                setGroups(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        getGroups();
    }, [user._id]);

    useEffect(() => {
        const getMessages = async () => {
            console.log('messages chat id', currentChat);
            try {
                const res = await axios.get(
                    `http://localhost:5000/messages/${currentChat?._id}`
                );

                setMessages(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        getMessages();
    }, [currentChat]);
    const handleVideo = async () => {
        const data = new FormData();
        console.log('video', media);
        data.append('file', media);
        data.append('upload_preset', 'instaClone');
        data.append('cloud_name', 'aditya-foundation');
        const res = await axios.post(
            'https://api.cloudinary.com/v1_1/aditya-foundation/image/upload',
            data
        );
        console.log('response', res.data.url);
        console.log('response', res.data);
        setMedia('');
        return res.data;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let mediaData = '';
        const curr_msg = newMessage;
        console.log('media data', media);
        console.log('msg data', curr_msg);
        // for attachment
        if (media !== '') {
            mediaData = await handleVideo();
        }

        console.log('Submitted media data', mediaData);
        const message = {
            sender: user._id,
            text: curr_msg,
            media: mediaData.url,
            mediaFormat: mediaData.resource_type,
            conversationId: currentChat._id
        };

        const receiverId = currentChat.members.filter(
            (member) => member !== user._id
        );
        console.log('reciever id', receiverId);
        socket.current.emit('sendMessage', {
            senderId: user._id,
            receiverId,
            text: newMessage,
            media: mediaData.url,
            mediaFormat: mediaData.resource_type,
            conversationId: currentChat._id
        });

        try {
            const res = await axios.post(
                'http://localhost:5000/messages',
                message
            );
            setMessages([...messages, res.data]);
            setNewMessage('');
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <Box
            className="primary-box"
            sx={{
                backgroundColor: 'secondary.light',
                minHeight: 'calc(100vh - 126px)'
            }}
        >
            <Grid container spacing={4}>
                <Grid item md={3}>
                    <Box textAlign="center" mx={5}>
                        <TextField
                            placeholder="Search for friends"
                            className="chatMenuInput"
                            size="large"
                            fullWidth
                            variant="standard"
                            sx={{ border: 'none' }}
                            InputProps={{
                                style: {
                                    background: '#fff',
                                    padding: 8,
                                    borderRadius: 12
                                }
                            }}
                        />
                        <Card
                            sx={{
                                backgroundColor: '#fff',
                                mt: 4
                            }}
                        >
                            {' '}
                            {conversations.map((c) => (
                                <CardContent>
                                    <Button onClick={() => setCurrentChat(c)}>
                                        {c.members[1]}
                                    </Button>
                                </CardContent>
                            ))}
                        </Card>
                    </Box>
                </Grid>
                <Grid item className="chatBox" md={9}>
                    <Box pr={5}>
                        <Card
                            className="chatBoxWrapper"
                            sx={{
                                backgroundColor: '#fff',
                                mt: 4,
                                borderRadius: 2,
                                maxHeight: '77vh',
                                overflow: 'auto'
                            }}
                        >
                            <CardContent>
                                {currentChat ? (
                                    <>
                                        <div className="chatBoxTop">
                                            {messages?.map((m) => (
                                                <Box
                                                    className="map-container"
                                                    display="flex"
                                                    justifyContent={
                                                        m.sender === user._id &&
                                                        'end'
                                                    }
                                                    ref={scrollRef}
                                                >
                                                    <Message
                                                        message={m}
                                                        own={
                                                            m.sender ===
                                                            user._id
                                                        }
                                                    />
                                                </Box>
                                            ))}
                                        </div>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Box
                                                className="chatBoxBottom"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <TextField
                                                    className="chatMessagesInput"
                                                    placeholder="write something..."
                                                    multiline
                                                    variant="filled"
                                                    sx={{ width: '500px' }}
                                                    onChange={(e) =>
                                                        setNewMessage(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={newMessage}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <div className="upload-btn-wrapper">
                                                                    <IconButton className="btn">
                                                                        <AttachFile />
                                                                    </IconButton>
                                                                    {
                                                                        media?.name
                                                                    }
                                                                    <input
                                                                        type="file"
                                                                        name="myfile"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setMedia(
                                                                                e
                                                                                    .target
                                                                                    .files[0]
                                                                            );
                                                                            console.log(
                                                                                'Media Attached',
                                                                                e
                                                                                    .target
                                                                                    .files[0]
                                                                            );
                                                                        }}
                                                                        required
                                                                    />
                                                                </div>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />

                                                <div>
                                                    {/* <span style={{ color: 'red' }}>
                                                    Upload Image
                                                </span>
                                                <input
                                                    type="file"
                                                    onChange={(e) =>
                                                        setMedia(
                                                            e.target.files[0]
                                                        )
                                                    }
                                                    required
                                                /> */}
                                                </div>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleSubmit}
                                                    sx={{
                                                        backgroundColor:
                                                            'secondary.light',
                                                        width: '40px',
                                                        height: '40px',
                                                        padding: 2,
                                                        borderRadius: '50%',
                                                        marginLeft: 3
                                                    }}
                                                >
                                                    <Send />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </>
                                ) : (
                                    <span className="noConversationText">
                                        Open a conversation to start a chat.
                                    </span>
                                )}
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Messenger;
