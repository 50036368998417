import { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { School } from '@mui/icons-material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Contacts from '../../../assets/images/import_contacts_custom.svg';
import { getUsersCount } from '../../../API/Statistics';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const Infographics1 = () => {
    const [statistics, setStats] = useState();
    useEffect(() => {
        getUsersCount().then((response) => setStats(response));
    }, []);

    <Box
        py={6}
        sx={{ backgroundColor: 'secondary.main' }}
        className="box-shadow"
    >
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <School fontSize="large" color="white" />
                    <Typography variant="h1" color="white" sx={{ ml: 0.5 }}>
                        {statistics?.instructors}
                    </Typography>
                </Box>

                <Typography
                    variant="h4"
                    color="white"
                    sx={{ textAlign: 'center' }}
                >
                    Instructors
                </Typography>
            </Grid>

            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <img src={Contacts} alt="studnts" width="54px" />
                    <Typography variant="h1" color="white" sx={{ ml: 0.5 }}>
                        {statistics?.students}
                    </Typography>
                </Box>
                <Typography variant="h4" color="white">
                    Students
                </Typography>
            </Grid>
        </Grid>
    </Box>;

    const labels = ['Janauary', 'February', 'March', 'April', 'May'];
    const options = {
        responsive: true,

        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true
            }
        }
    };

    const data = {
        labels,
        title: ['No. of students'],
        datasets: [
            {
                label: 'Instructors',
                data: [1, 2, 3, 4, 5],
                backgroundColor: 'rgba(82, 146, 221, 0.8)'
            },
            {
                label: 'Students',
                data: [5, 7, 3, 5, 6],
                backgroundColor: 'rgb(82, 146, 221)'
            }
        ]
    };
    return (
        <Box>
            <Card>
                <CardContent>
                    <Bar options={options} data={data} />
                </CardContent>
            </Card>
        </Box>
    );
};

export default Infographics1;
