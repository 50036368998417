import { Button, Typography } from '@mui/material';
import { blockUser, unBlockUser } from '../../../API/Users';
import { toast } from 'react-toastify';

const blockUsers = async (id) => {
    const response = await blockUser(id);
    console.log('response of blockuser', response);
};

const unBlockUsers = async (id) => {
    const response = await unBlockUser(id);
    console.log('response of blockuser', response);
};

export const columns = [
    // {
    //     field: 'sno',
    //     headerName: 'Sno.',
    //     renderCell: (params) => {

    //         params.api.getRowIndex(params.row.id) + 1;
    //     }
    // },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1
    },
    {
        field: 'updatedAt',
        headerName: 'Created On',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: (params) => {
            // Assuming updatedAt is a valid date string
            const date = new Date(params.value);
            return date.toLocaleString(); // Adjust the formatting as needed
        }
    },
    {
        field: 'majorCategory',
        headerName: 'Major Category',
        align: 'center',
        type: 'number',
        headerAlign: 'center',
        flex: 1
    },
    {
        field: 'subCategory',
        headerName: 'Sub Category',
        sortable: false,
        type: 'number',
        headerAlign: 'center',
        align: 'center',
        flex: 1
    },
    {
        field: 'isBlocked',
        headerName: 'User Status',
        flex: 1,
        type: 'boolean',
        renderCell: (params) => {
            return params.value ? (
                <Button
                    variant="text"
                    color="secondary"
                    onClick={() => {
                        unBlockUsers(params.row._id);
                        toast.success('User has been unblocked');
                    }}
                >
                    Unblock
                </Button>
            ) : (
                <Button
                    variant="text"
                    color="secondary"
                    onClick={() => {
                        blockUsers(params.row._id);
                        toast.success('User has been blocked');
                    }}
                >
                    Block
                </Button>
            );
        }
    }
];
