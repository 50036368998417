import Axios from 'axios';
import Apiconfig from '../config/ApiConfig';

const token = localStorage.getItem('token');

export const getAllStudents = () =>
    Axios.get(Apiconfig.users.getAllStudents, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then((response) => response.data.data);

export const getAllInstructors = () =>
    Axios.get(Apiconfig.users.getAllInstructors, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then((response) => response.data.data);
export const getAllAssistants = () =>
    Axios.get(Apiconfig.users.getAllAssistants, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then((response) => response.data.data);

// export const getAllUsers = () =>
//     Axios.get(Apiconfig.statistics.getAllUsers, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     }).then((response) => response.data.data.users);
export const getAllUsers = async () => {
    try {
        const response = await Axios.get(Apiconfig.statistics.getAllUsers, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        console.log('getAllUsers', response);
        return response.data.data.users;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};
