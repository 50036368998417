import { Box, Card, CardContent, Typography } from '@mui/material';
import { format } from 'timeago.js';
import PropTypes from 'prop-types';

const Message = ({ message, own }) => {
    console.log('own', own);
    return (
        <Card
            textAlign={own ? 'end' : 'start'}
            color={own ? '#fff' : '#212b36'}
            mt={2}
            width="50%"
            sx={
                own
                    ? {
                          borderBottomLeftRadius: '21px',
                          borderBottomRightRadius: '0px',
                          borderTopLeftRadius: '21px',
                          borderTopRightRadius: '21px',
                          mt: 2
                      }
                    : {
                          borderBottomRightRadius: '21px',
                          borderTopLeftRadius: '21px',
                          borderTopRightRadius: '21px',
                          borderBottomLeftRadius: '0px',
                          mt: 2
                      }
            }
        >
            <CardContent
                sx={
                    own
                        ? {
                              color: '#fff',
                              backgroundColor: 'primary.main',
                              p: 1
                          }
                        : { backgroundColor: 'secondary.light', p: 1 }
                }
            >
                <div className="messageTop">
                    <Box p={1}>
                        {message.mediaFormat === 'image' &&
                            message.media !== '' && (
                                <img
                                    src={message.media}
                                    alt="myimage"
                                    width="300px"
                                    style={{ borderRadius: '13px' }}
                                />
                            )}
                    </Box>
                    <Box p={1}>
                        {' '}
                        <p className="messageText">{message.text}</p>
                        <Typography
                            variant="body2"
                            sx={
                                own
                                    ? { color: 'rgba(255,255,255,0.5)' }
                                    : { color: 'rgba(33,43,54,0.7)' }
                            }
                        >
                            {format(message.createdAt)}
                        </Typography>
                    </Box>
                </div>
            </CardContent>
        </Card>
    );
};

Message.propTypes = {
    message: PropTypes.objectOf(PropTypes.any).isRequired,
    own: PropTypes.bool.isRequired
};

export default Message;
