import { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    useTheme,
    Card,
    CardContent
} from '@mui/material';
import { School, HeadsetMic } from '@mui/icons-material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Contacts from '../../../assets/images/import_contacts_custom.svg';
import Network from '../../../assets/Icons/N&S.svg';
import CyberSecurity from '../../../assets/Icons/CyberSecurity.svg';
import Opserver from '../../../assets/Icons/Opserver.svg';
import { getUsersCount } from '../../../API/Statistics';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const Infographics = () => {
    const theme = useTheme();
    const [statistics, setStats] = useState([]);
    useEffect(() => {
        getUsersCount().then((response) => setStats(response));
    }, []);

    const labels = ['Janauary', 'February', 'March', 'April', 'May'];
    const options = {
        responsive: true,

        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true
            }
        }
    };
    const data1 = {
        labels,
        datasets: [
            {
                label: 'Users',
                data: [1, 2, 3, 4, 5],
                backgroundColor: 'rgba(82, 146, 221, 0.8)'
            }
        ]
    };
    const data = {
        labels,
        datasets: [
            {
                label: 'Instructors',
                data: [1, 2, 3, 4, 5],
                backgroundColor: 'rgba(82, 146, 221, 0.8)'
            },
            {
                label: 'Students',
                data: [5, 7, 3, 5, 6],
                backgroundColor: 'rgb(82, 146, 221)'
            },
            {
                label: 'Lab Assistants',
                data: [8, 7, 6, 5, 4, 3],
                backgroundColor: 'rgba(82, 146, 221, 0.5)'
            }
        ]
    };
    return (
        <>
            <Box
                py={6}
                sx={{ backgroundColor: 'secondary.main' }}
                className="box-shadow"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <School fontSize="large" color="white" />
                            <Typography
                                variant="h1"
                                color="white"
                                sx={{ ml: 0.5 }}
                            >
                                {statistics?.instructors}
                            </Typography>
                        </Box>

                        <Typography
                            variant="h4"
                            color="white"
                            sx={{ textAlign: 'center' }}
                        >
                            Instructors
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                textAlign: 'center',
                                borderLeft: '4px solid #fff',
                                borderRight: '4px solid #fff'
                            },
                            [theme.breakpoints.down('sm')]: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    textAlign: 'center',
                                    borderTop: '4px solid #fff',
                                    borderBottom: '4px solid #fff',
                                    width: '50%'
                                }
                            }}
                        >
                            <HeadsetMic fontSize="large" color="white" />
                            <Typography
                                variant="h1"
                                color="white"
                                sx={{ ml: 0.5 }}
                            >
                                {statistics?.labAssistants}
                            </Typography>
                        </Box>
                        <Typography variant="h4" color="white">
                            Lab Assistants
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img src={Contacts} alt="students" width="54px" />
                            <Typography
                                variant="h1"
                                color="white"
                                sx={{ ml: 0.5 }}
                            >
                                {statistics?.students}
                            </Typography>
                        </Box>
                        <Typography variant="h4" color="white">
                            Students
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box my={10}>
                <Grid container justifyContent="space-around">
                    <Grid item xs={12} md={5}>
                        {' '}
                        <Card>
                            <CardContent>
                                <Bar options={options} data={data1} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Card>
                            <CardContent>
                                <Bar options={options} data={data} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>{' '}
            </Box>
            <Box
                py={5}
                px={{ xs: 4, md: 9, lg: 17 }}
                sx={{ backgroundColor: 'secondary.main' }}
            >
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <Card className="box-shadow">
                            <Card
                                content
                                sx={{ textAlign: 'center', pt: 5, pb: 4 }}
                            >
                                <img
                                    src={Network}
                                    alt="netSec"
                                    style={{ display: 'inline' }}
                                />
                                <Typography variant="h3" color="secondary">
                                    Network & Security
                                </Typography>
                                <Typography variant="body1">
                                    Most active category
                                </Typography>
                            </Card>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className="box-shadow">
                            <Card
                                content
                                sx={{ textAlign: 'center', pt: 5, pb: 4 }}
                            >
                                <img
                                    src={CyberSecurity}
                                    alt="netSec"
                                    style={{ display: 'inline' }}
                                />
                                <Typography variant="h3" color="secondary">
                                    Cyber Security
                                </Typography>
                                <Typography variant="body1">
                                    Least active category
                                </Typography>
                            </Card>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className="box-shadow">
                            <Card
                                content
                                sx={{ textAlign: 'center', pt: 5, pb: 4 }}
                            >
                                <img
                                    src={Opserver}
                                    alt="netSec"
                                    style={{ display: 'inline' }}
                                />
                                <Typography variant="h3" color="secondary">
                                    Operating Servers
                                </Typography>
                                <Typography variant="body1">
                                    Last created category
                                </Typography>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Infographics;
