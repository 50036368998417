const breadcrumb = [
    {
        id: '01',
        breadItem: 'Courses',
        link: '/dashboard/courses'
    },
    {
        id: '02',
        breadItem: 'Create new course'
    }
];

export const analysisBread = [{ id: '01', breadItem: 'Analysis' }];
export const usersBread = [{ id: '01', breadItem: 'Users' }];
export const AddusersBread = [
    { id: '01', breadItem: 'Users' },
    { id: '02', breadItem: 'Add Users' }
];

export default breadcrumb;
