import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

// localStorage.setItem('mode', false);
const App = () => (
    <ThemeConfig>
        <GlobalStyles />
        <Router />
    </ThemeConfig>
);

export default App;
