const localMode = localStorage.getItem('mode');
const initialState = {
    mode: localMode && localMode
};
function toggleThemeReducer(state = initialState, action = {}) {
    switch (action && action.type) {
        case 'TOGGLE':
            if (localMode) {
                localStorage.setItem('mode', !localMode);
            }
            localStorage.setItem('mode', !state.mode);
            return {
                mode: !state.mode
            };
        default:
            return state;
    }
}
export default toggleThemeReducer;
