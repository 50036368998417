import Axios from 'axios';
import Apiconfig from '../config/ApiConfig';

export const changeLogo = async (path) => {
    try {
        const response = await Axios.post(Apiconfig.ui.changeLogo, path);
        return response;
    } catch (err) {
        return err.message;
    }
};

export const getLogo = async () => {
    try {
        const response = await Axios.get(Apiconfig.ui.getLogo);
        return response;
    } catch (err) {
        return null;
    }
};
