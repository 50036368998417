const breadcrumb = [
    {
        id: '01',
        breadItem: 'Courses',
        link: '/dashboard/courses'
    },
    {
        id: '02',
        breadItem: 'View Course'
    }
];

export default breadcrumb;

export const allCoursesBreadcrumb = [
    {
        id: '01',
        breadItem: 'All Courses',
        link: '/dashboard/courses/courses'
    },
    {
        id: '02',
        breadItem: 'My Courses'
    }
];

export const trackProgressBreadcrumb = [
    {
        id: '01',
        breadItem: 'Courses',
        link: '/dashboard/courses/courses'
    },
    {
        id: '02',
        breadItem: 'Track Progress'
    }
];

export const pollsBreadcrumb = [
    {
        id: '01',
        breadItem: 'Dashboard',
        link: '/dashboard'
    },
    {
        id: '02',
        breadItem: 'Polls'
    }
];

export const surveyBreadcrumb = [
    {
        id: '01',
        breadItem: 'Dashboard',
        link: '/dashboard'
    },
    {
        id: '02',
        breadItem: 'Survey'
    }
];

export const surveyDetailsBreadcrumb = [
    {
        id: '01',
        breadItem: 'Dashboard',
        link: '/dashboard'
    },
    {
        id: '01',
        breadItem: 'Survey',
        link: '/dashboard/survey'
    },
    {
        id: '02',
        breadItem: 'Survey Details'
    }
];
