const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 950,
        lg: 1536,
        xl: 1920
    }
};

export default breakpoints;
