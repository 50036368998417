import { Chip, Divider, Typography, Grid } from '@mui/material';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import Apiconfig from '../../../config/ApiConfig';

const src = 'http://localhost:5000';
const ListView = ({ item }) => (
    // container
    <>
        {' '}
        <Grid container sx={{ mb: 2, mt: 2 }}>
            <Grid item md={2}>
                <img
                    src={Apiconfig?.url + item.thumbnail}
                    alt="myCourse"
                    width="100px"
                    height="88px"
                />
            </Grid>
            <Grid item md={2}>
                <Chip label={item?.majorCategory} />
                <Typography variant="body" width="282px">
                    {item?.courseName}
                </Typography>
            </Grid>
            {/* box 2 */}
            <Grid item md={8} sx={{ textAlign: 'end' }}>
                <Typography variant="subtitle2">Created on</Typography>
                <Typography variant="body2">
                    {' '}
                    {moment(item?.updatedAt).format('DD/MM/YYYY h:mmA')}
                </Typography>
                {/* <Typography variant="body2"> {item?.updatedAt}</Typography> */}
                <Typography variant="caption">
                    {item.students.length <= 1
                        ? item.students.length + ' student'
                        : item.students.length + ' students'}{' '}
                    enrolled
                </Typography>
            </Grid>
        </Grid>{' '}
        <Divider />
    </>
);

export default ListView;

ListView.propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired
};
