import * as Yup from 'yup';

export const initialValues = {
    agenda: '',
    description: '',
    // resource: '',
    role: 'student'
};

export const validationSchema = Yup.object({
    agenda: Yup.string().required('Agenda is required'),
    description: Yup.string().required('Description is required'),
    // resource: Yup.string().required('resource is required'),
    role: Yup.string().required('Role is required')
});
