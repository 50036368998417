import { useState } from 'react';
import { Box, Grid, Tabs, Tab, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BreadCrumb from '../../../common/components/Breadcrumb';
import { analysisBread } from '../../../utils/StaticData/Breadcrumbs/createCourse';
import Content from './Content';
import Infographics from './Infographics';

const Analysis = () => {
    const [value, setValue] = useState(0);
    const [date, setDate] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const tabs = [
        {
            id: 0,
            label: 'Infographics',
            component: <Infographics />
        },
        {
            id: 1,
            label: 'Users',
            component: <Content users />
        },
        {
            id: 2,
            label: 'Courses',
            component: <Content courses />
        }
    ];
    return (
        <>
            <Box px={{ xs: 4.5, md: 9 }}>
                <BreadCrumb breadItems={analysisBread} />

                <Grid container spacing={{ xs: 5 }}>
                    <Grid item md={7}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="analysis-tabs"
                            textColor="secondary"
                            indicatorColor="secondary"
                        >
                            <Tab
                                label="INFOGRAPHICS"
                                id="0"
                                aria-controls="analysis-tab-0"
                            />
                            <Tab
                                label="USERS"
                                id="1"
                                aria-controls="analysis-tab-1"
                            />
                            <Tab
                                label="COURSES"
                                id="2"
                                aria-controls="analysis-tab-2"
                            />
                        </Tabs>
                    </Grid>
                    <Grid item md={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {/* <DatePicker
                                views={['year', 'month']}
                                label="Year and Month"
                                minDate={new Date('2000-01-01')}
                                maxDate={new Date('2100-12-31')}
                                value={date}
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} helperText={null} />
                                )}
                            /> */}
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Box>
            {tabs.map(
                (obj, index) =>
                    value === index && (
                        <Box mt={5} key={obj?.id}>
                            {/* <Typography variant="h2"> */}
                            {obj?.component}
                            {/* </Typography> */}
                        </Box>
                    )
            )}
        </>
    );
};

export default Analysis;
