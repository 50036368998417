import { Outlet } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import logo from '../../../assets/Logo.svg';
import './index.css';

const LoginLayout = () => {
    const theme = useTheme();
    theme;
    return (
        <Grid
            container
            className="authLayout-wrapper"
            sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
            <Grid item xs={12} md={6} className="static-container">
                <Box
                    className="auth-left"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pl={10}
                    height="100vh"
                    sx={{ backgroundColor: 'primary.main' }}
                >
                    <img src={logo} alt="logo" width="70%" />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} className="dynamic-container">
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Outlet />
                </Box>
            </Grid>
        </Grid>
    );
};

export default LoginLayout;
