import { useState, useEffect } from 'react';
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Box, Typography, AppBar, Toolbar } from '@mui/material';
import { Notifications, Menu, Logout } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import jwtDecode from 'jwt-decode';
import LogoLayout from '../logo/logo';
import { getLogo } from '../../../API/uiApi';
import './index.css';
import Apiconfig from '../../../config/ApiConfig';

const DashboardLayout = () => {
    // most active course, least active course, last
    const location = useLocation();
    const currentPath = location?.pathname.split('/', 3).join('/');
    // const currentPath = location?.pathname;
    const theme = useTheme();
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };
    const [user, setUser] = useState(null);
    const [logo, setLogo] = useState('');
    const [logoPath, setLogoPath] = useState('');
    const highlighter = (path) => (currentPath === path ? 'active' : null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const data = jwtDecode(token);
        setUser(data);
    }, [currentPath]);

    const setLogoPathOnce = () => {
        if (logoPath != null) {
            setLogo(logoPath);
        }
    };

    useEffect(() => {
        getLogo().then((value) =>
            setLogoPath(Apiconfig?.url + value?.data?.data?.logo)
        );
        setLogoPathOnce();
    });

    const onChangeLogo = (logos) => {
        setLogo(logos);
    };

    return (
        <Box className="main-wrapper">
            <Box>
                <AppBar
                    position="static"
                    sx={{
                        [theme.breakpoints.down('md')]: { px: 3 },
                        [theme.breakpoints.up('md')]: { px: 12 }
                    }}
                    elevation={0}
                    maxwidth="sm"
                    className="box-shadow"
                >
                    <Toolbar disableGutters elevation={4}>
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ flexGrow: 1 }}
                        >
                            {' '}
                            <Menu
                                sx={{
                                    mr: 1,
                                    [theme.breakpoints.up('md')]: {
                                        display: 'none'
                                    }
                                }}
                            />
                            {logo && (
                                <img
                                    src={logo}
                                    alt="logo"
                                    style={{
                                        width: '204px',
                                        height: '30px',
                                        bordeRadius: '10%'
                                    }}
                                />
                            )}
                        </Box>{' '}
                        {/* <Logout onClick={logout} sx={{ mx: 1 }} /> */}
                        <Notifications
                            sx={{
                                [theme.breakpoints.down('sm')]: { mx: 1 },
                                [theme.breakpoints.up('sm')]: { mr: 3 }
                            }}
                        />
                        <LogoLayout onPathChange={onChangeLogo} />
                        <Typography
                            variant="body1"
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    display: 'none'
                                }
                            }}
                        >
                            {user?.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box
                sx={{
                    backgroundColor: 'primary.light',
                    [theme.breakpoints.down('md')]: {
                        display: 'none'
                    }
                }}
            >
                <Box
                    maxWidth="xxl"
                    sx={{
                        display: 'flex',
                        boxShadow: 10,
                        backgroundColor: 'primary.light',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                    px={14}
                >
                    {' '}
                    <Box
                        display="flex"
                        alignItems="center"
                        className="navlink-wrapper"
                        height="62px"
                        sx={{ color: 'primary.contrastText' }}
                    >
                        <Link
                            to="/dashboard"
                            style={{ marginRight: 32 }}
                            name="dashboard"
                            className={highlighter('/dashboard')}
                        >
                            Dashboard
                        </Link>
                        <Link
                            to="/dashboard/courses"
                            name="allCourses"
                            // style={{ marginLeft: 32 }}
                            style={{ marginRight: 32 }}
                            // onClick={activeHandler}
                            className={
                                highlighter('/dashboard/courses')
                                // user?.role === 'instructor'
                                //     ? highlighter('/dashboard/courses')
                                //     : highlighter('/dashboard/courses')
                            }
                        >
                            <Typography color="secondary.navColorText">
                                Courses
                            </Typography>
                        </Link>
                        <Link
                            to="/dashboard/users"
                            name="Users"
                            style={{ marginRight: 32 }}
                            className={highlighter('/dashboard/users')}
                        >
                            Users
                        </Link>
                        <Link
                            to="/dashboard/category"
                            style={{ marginLeft: 32, marginRight: 32 }}
                            name="Categories"
                            className={highlighter('/dashboard/category')}
                        >
                            Categories
                        </Link>
                        <Link
                            to="/dashboard/analysis"
                            style={{ marginLeft: 32, marginRight: 32 }}
                            name="Analysis"
                            className={highlighter('/dashboard/analysis')}
                        >
                            Analysis
                        </Link>
                        <Link
                            to="/dashboard/circular"
                            style={{ marginLeft: 32 }}
                            name="Circular"
                            className={highlighter('/dashboard/circular')}
                        >
                            Circular
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box pb={14}>
                <Outlet />
            </Box>
            <Box
                className="footer"
                textAlign="center"
                sx={{ backgroundColor: 'primary.main', p: 3, color: '#fff' }}
            >
                <Typography variant="body1">
                    Copyright © BhumiiTech 2021-2022
                </Typography>
            </Box>
        </Box>
    );
};

export default DashboardLayout;
