export default function Icon() {
    return {
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeLarge: {
                    width: '54px',
                    height: '54px'
                },
                colorWhite: {
                    color: '#fff'
                }
            }
        }
    };
}
