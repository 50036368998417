import Axios from 'axios';
import ApiConfig from '../config/ApiConfig';

export const getAdminDashStats = async (courseId) => {
    const token = localStorage.getItem('token');
    console.log('course Id', courseId);
    const response = await Axios.get(ApiConfig.statistics.getAdminDashStats, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    const responseData = response.data.data;
    return responseData;
};

export const getUsersCount = async (courseId) => {
    console.log('course Id', courseId);

    const token = localStorage.getItem('token');
    const response = await Axios.get(ApiConfig.statistics.getUsersCount, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    const responseData = response.data.data;
    return responseData;
};
