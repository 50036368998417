import { Box, Grid, Typography } from "@mui/material"
import BreadCrumb from "../../../common/components/Breadcrumb"
import { surveyDetailsBreadcrumb } from "../../../utils/StaticData/Breadcrumbs/Course"
import { useParams } from "react-router-dom"
import { getVotesOfSurvey } from "../../../API/Course"
import { useEffect, useState } from "react"
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './index.css';

export const SurveyDetails = () => {

    ChartJS.register(ArcElement);
    const { id } = useParams();
    const [data, setData] = useState({});

    const getVotes = async () => {
        const res = await getVotesOfSurvey(id);
        setData(res);
    }
    console.log("data", data)

    useEffect(() => {
        getVotes();
    }, [])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        tooltip: {
            enabled: true,
            bodyFont: {
                size: 16,
            },
        },
        plugins: {
            title: {
                responsive: true,
                animation: {
                    animateScale: true,
                }
            },
            legend: {
                position: 'right',
                labels: {
                    font: {
                        size: 15
                    },
                    padding: 30
                }
            },
        }

    }

    const calculatePercentages = (votes) => {
        const totalVotes = Object.values(votes).reduce((acc, curr) => {
            if (typeof curr === 'number') {
                return acc + curr;
            }
            return acc;
        }, 0);
        return Object.keys(votes).map((option) => {
            if (typeof votes[option] === 'number') {
                const percentage = ((votes[option] / totalVotes) * 100).toFixed(2);
                return `- ${percentage}%`;
            }
            return null;
        });
    };


    const getRandomColor = () => {
        const min = 0;
        const max = 255;

        const red = min;
        const green = min;
        const blue = Math.floor(Math.random() * (max - min + 1)) + min;

        return `rgb(${red}, ${green}, ${blue})`;
    };

    return (
        <>
            <Box px={{ md: 14, sm: 3, xs: 3 }}>
                <Box>
                    <BreadCrumb breadItems={surveyDetailsBreadcrumb} />
                </Box>
                <Grid container
                    px={{ md: 10, sm: 0 }}
                    py={5}
                >
                    <Grid item p={3} width="100%" md={12}>
                        {Object.entries(data || {}).length && data?.attempts.map((attempt, index) => (
                            <Box key={index}>

                                {attempt.questions.map((question, qIndex) => (

                                    <Box key={qIndex} sx={{
                                        boxShadow: 3,
                                        p: 2,
                                        borderRadius: 2,
                                        my: 2
                                    }}>

                                        <Typography mb={2} sx={{
                                            fontWeight: 500
                                        }}>
                                            {` ${qIndex + 1}`}. {question?.question}?
                                        </Typography>

                                        {attempt[`attemptsOfQuestion${qIndex + 1}`] > 0 ? (
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                            }}
                                                mb={2}
                                            >
                                                <Pie
                                                    options={options}
                                                    data={{
                                                        labels: Object.keys(data[`votesofQuestion${qIndex + 1}`][0]).filter((key) => key !== '_id'),
                                                        datasets: [
                                                            {
                                                                data: Object.values(data[`votesofQuestion${qIndex + 1}`][0]).filter((value, i) => {
                                                                    const key = Object.keys(data[`votesofQuestion${qIndex + 1}`][0])[i];
                                                                    return key !== '_id';
                                                                }),
                                                                backgroundColor: Array.from({
                                                                    length: Object.keys(data[`votesofQuestion${qIndex + 1}`][0]).length - 1
                                                                },
                                                                    () => getRandomColor()),
                                                            }
                                                        ],
                                                    }}
                                                />
                                                {/* <Box >
                                                    {calculatePercentages(data[`votesofQuestion${qIndex + 1}`][0]).map((percentage, i) => (
                                                        <Typography key={i}>{percentage}</Typography>
                                                    ))}
                                                </Box> */}
                                            </Box>
                                        ) : (
                                            <p>No votes recorded yet.</p>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}