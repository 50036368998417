export default function Button(theme) {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    },

                    borderRadius: 4,
                    [theme.breakpoints.down('sm')]: {
                        padding: '6px 12px'
                    },
                    [theme.breakpoints.up('sm')]: {
                        padding: '10px 24px 10px 24px',
                        fontWeight: 570,
                        letterSpacing: '1.9px'
                    }
                },

                sizeLarge: {
                    padding: '10px, 24px, 10px, 24px'
                },
                containedInherit: {
                    color: theme.palette.grey[800],

                    '&:hover': {
                        backgroundColor: theme.palette.grey[400]
                    }
                },

                outlinedInherit: {
                    border: `1px solid ${theme.palette.grey[500_32]}`,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    }
                },
                textInherit: {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    }
                }
            }
        }
    };
}
