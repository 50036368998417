import * as Yup from 'yup';

export const initialValues = {
    name: '',
    userId: '',
    role: 'student',
    userName: '',
    password: '',
    email: '',
    majorCategory: '',
    subCategory: ''
};

export const validationSchema = Yup.object({
    userId: Yup.string().required('User Id is required'),
    name: Yup.string().required('Name is required'),
    role: Yup.string().required('User Type is required'),
    majorCategory: Yup.string().required('Major Category is required'),
    subCategory: Yup.string().required('Sub Category is required'),
    userName: Yup.string().required('User Name is required'),
    email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    password: Yup.string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required')
});
