import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SubCatCourse from './subCatCourse';
import SubCatUser from './subCatUser';
import BreadCrumb from '../../../common/components/Breadcrumb';

const breadcrumb = [
    {
        id: '01',
        breadItem: 'categories',
        link: '/dashboard/category'
    },
    {
        id: '02',
        breadItem: 'subcategory',
        link: '/dashboard/category/subcategory'
    },
    {
        id: '03',
        breadItem: 'sub-categories-details'
    }
];

const SubCAtegoryDetails = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box mb={1.5} mx={{ xs: 4, md: 5 }}>
                {' '}
                <BreadCrumb breadItems={breadcrumb} />
            </Box>
            <Box sx={{ width: '100%', typography: 'body1' }} px={4}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab label="Courses" value="1" />
                            <Tab label="USERS" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <SubCatCourse />
                    </TabPanel>
                    <TabPanel value="2">
                        <SubCatUser />
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    );
};

export default SubCAtegoryDetails;
