import { Box, Card, Typography } from "@mui/material"
import parse from 'html-react-parser';
import { format } from 'timeago.js';
import Apiconfig from "../../../config/ApiConfig";

export const DataCard = ({ data }) => {
    return (
        <>
            <Card sx={{ p: 2 }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography>
                        Title : {data?.agenda}
                    </Typography>
                    <Typography>
                        {format(data?.createdAt)}
                    </Typography>
                </Box>
                <Typography>
                    {parse(`${data?.description}`)}
                </Typography>
                    <Typography display='flex' justifyContent='flex-end'>
                        <a
                            target="_blank"
                            href={`${Apiconfig.url}${data.doc}`}
                        >
                            Download Document
                        </a>
                    </Typography>
            </Card>
        </>
    )
}