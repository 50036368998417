import { Card, CardContent, Box, Typography, useTheme } from '@mui/material';
import { Person, Category, MenuBook } from '@mui/icons-material';
import PropTypes from 'prop-types';

const renderIcon = (text) => {
    switch (text) {
        case 'users':
            return <Person fontSize="large" />;
        case 'categories':
            return <Category fontSize="large" />;
        case 'courses':
            return <MenuBook fontSize="large" />;
        default:
            null;
    }
    return null;
};
const CourseDetailCard = ({ item }) => {
    const theme = useTheme();
    return (
        <Card sx={{ width: '100%' }}>
            <CardContent
                sx={{ borderLeft: `10px solid ${theme.palette.primary.light}` }}
            >
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h3"> {item.number}</Typography>
                    {renderIcon(item.name)}
                </Box>

                <Typography variant="body1"> {item.name}</Typography>
            </CardContent>
        </Card>
    );
};

CourseDetailCard.propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CourseDetailCard;
