import { useState, useEffect } from 'react';
import { Box, Button, IconButton, Chip, Tabs, Tab } from '@mui/material';
import { Link } from 'react-router-dom';
import { FilterList } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import {
    getAllStudents,
    getAllInstructors,
    getAllAssistants
} from '../../../API/getUsers';
import BreadCrumb from '../../../common/components/Breadcrumb';
import { usersBread } from '../../../utils/StaticData/Breadcrumbs/createCourse';
import { columns } from './tableColumns';

const Users = () => {
    const handleDelete = () => console.log('Chip deleted');
    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (value === 0) {
            getAllStudents().then((response) => setData(response));
        } else if (value === 1) {
            getAllInstructors().then((response) => setData(response));
        } else {
            getAllAssistants().then((response) => setData(response));
        }
    }, [value, data]);

    return (
        <Box px={{ xs: 4.5, md: 9 }} className="bread-button-container">
            <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={{ xs: 'column' }}
            >
                <BreadCrumb breadItems={usersBread} />
                <Box
                    className="button-container"
                    display="flex"
                    flexDirection={{ xs: 'row-reverse', md: 'column' }}
                    justifyContent={{ xs: 'space-between' }}
                    alignItems="end"
                >
                    <Box>
                        <Link to="/dashboard/users/add-users">
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                            >
                                Add Users
                            </Button>
                        </Link>
                    </Box>
                    {/* <Box>
                        <IconButton color="secondary" sx={{ mt: 2 }}>
                            <FilterList sx={{ mr: 1 }} />
                            Filter
                        </IconButton>
                    </Box> */}
                </Box>
            </Box>
            <Chip
                label="All Categories"
                variant="contained"
                onDelete={handleDelete}
            />
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="users-tabs"
                textColor="secondary"
                indicatorColor="secondary"
            >
                <Tab label="STUDENTS" id="0" aria-controls="users-tab-0" />
                <Tab label="INSTRUCTORS" id="1" aria-controls="users-tab-1" />
                {/* <Tab
                    label="LAB ASSISTANTS"
                    id="2"
                    aria-controls="users-tab-2"
                /> */}
            </Tabs>
            <Box height={400} width="100%" mt={1}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    getRowId={(row) => row?._id}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </Box>
        </Box>
    );
};

export default Users;
