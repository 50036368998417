"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ATTR_TIMEAGO_TID = 'timeago-tid';
var ATTR_DATETIME = 'datetime';
/**
 * get the datetime attribute, `datetime` are supported.
 * @param node
 * @returns {*}
 */
function getDateAttribute(node) {
    return node.getAttribute(ATTR_DATETIME);
}
exports.getDateAttribute = getDateAttribute;
/**
 * set the node attribute, native DOM
 * @param node
 * @param timerId
 * @returns {*}
 */
function setTimerId(node, timerId) {
    // @ts-ignore
    node.setAttribute(ATTR_TIMEAGO_TID, timerId);
}
exports.setTimerId = setTimerId;
/**
 * get the timer id
 * @param node
 */
function getTimerId(node) {
    return ~~node.getAttribute(ATTR_TIMEAGO_TID);
}
exports.getTimerId = getTimerId;
