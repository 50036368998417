import { Box, Breadcrumbs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Seperator from '../../../assets/images/bc-sep.svg';

const BreadCrumb = ({ breadItems }) => (
    <Box my={2}>
        <Breadcrumbs
            aria-label="breadcrumb"
            color="primary"
            separator={<img src={Seperator} alt="seperator" />}
        >
            {breadItems.map((obj) =>
                obj.link ? (
                    <Link to={obj.link} color="primary" key={obj.id}>
                        {' '}
                        <Typography variant="caption">
                            {' '}
                            {obj.breadItem}
                        </Typography>
                    </Link>
                ) : (
                    <Typography variant="caption" key={obj.id}>
                        {obj.breadItem}
                    </Typography>
                )
            )}
        </Breadcrumbs>
    </Box>
);

BreadCrumb.propTypes = {
    breadItems: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default BreadCrumb;
